$(document).ready(function () {   

  $('.scroll-link').click(function (e) {
    e.preventDefault();
    $(window).stop(true).scrollTo(this.hash, { 
      duration: 1500,
      offset: 0,
      easing: 'easeOutCirc'  
    });
  });

});

$(document).on('lazyloaded', function (e) { 
  var $target = $(e.target);
  var $parent = $target.parent();
  if ($parent.hasClass("ae-img-spinner")) {
    $parent.addClass("ae-loaded"); 
  }
});

$(window).on("load", function () {
  $(".ae-img-spinner:not(.ae-loaded) .lazyloaded").each(function () {
    $(this).parent().addClass("ae-loaded");
    $(this).addClass("ae-loaded");
  });
});

$(window).on("resize", function () { 
  switchLazyBg(".ae-main-container");
});

/***** FUNCTIONS  *****/

function switchLazyBg(item) {
  var $h = $(item);
  var bg = $h.attr('data-bg');
  if ($h.hasClass("lazyloaded")) {
    if ($(window).width() <= 576) {
      bg = $h.attr('data-bg-mobile'); 
    }

    var current = $h.css("background-image");
    if (current.indexOf(bg) === -1) {

      $h.css({
        "background-image": 'url(' + bg + ')' 
      });

    }
  } 
}